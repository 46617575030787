body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Autocomplete*/
.css-2b097c-container {
  width: 100% !important;
}

.css-1hwfws3 {
  width: 100% !important;
}

/*Fin Autocomplete*/

.btnLila {
  background-color: #6A3E98 !important;
  border-color: #8e44ad;
  border-radius: 50px !important;
}

.btnSuccess {
  background-color: #2ecc71 !important;
  border-color: #27ae60;
  border-radius: 50px !important;
}

.btnInfo {
  background-color: #1abdaf !important;
  border-color: #16a195;
  border-radius: 50px !important;
}

.btnWarning {
  background-color: #f1c40f !important;
  border-color: #f39c12;
  border-radius: 50px !important;
}

.btnDefault {
  background-color: #ecf0f1 !important;
  border-color: #bdc3c7;
  border-radius: 50px !important;
}

.btnInverse {
  background-color: #95a5a6 !important;
  border-color: #7f8c8d;
  border-radius: 50px !important;
}

.btnOrange {
  background-color: #f08f65 !important;
  border-color: #f39c12;
  border-radius: 50px !important;
}

.btnDanger {
  background-color: #e74c3c !important;
  border-color: #c0392b;
  border-radius: 50px !important;
}

.btnDark {
  background-color: #34495e !important;
  border-color: #34495e;
  border-radius: 50px !important;
}

.btnWhite {
  background-color: transparent !important;
  border-color: #ffffff;
  border-radius: 50px !important;
}

.btnWhiteBorder {
  background-color: #ffffff !important;
  border-color: #c2c2c2;
  border-radius: 50px !important;
}

.btnSecundary {
  background-color: #0f70b7 !important;
  border-color: #0d5e97;
  border-radius: 50px !important;
}

.btnBlueLight {
  background-color: #3498db !important;
  border-color: #3187c0;
  border-radius: 50px !important;
}

.btnBorder {
  border-radius: 50px !important;
}

.btnSky {
  background-color: #2aa9f5 !important;
  border-color: #2aa9f5;
  border-radius: 50px !important;
}

.btnNavy {
  background-color: #2aa9f5 !important;
  border-color: #2aa9f5;
  border-radius: 50px !important;
}

.btnCyan {
  background-color: #7fe5dd !important;
  border-color: rgb(101, 182, 175);
  border-radius: 50px !important;
}

.ant-input-number-input {
  text-align: right !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;